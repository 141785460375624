import { PageProps } from 'gatsby';
import React from 'react';

import { useAppContext } from '@contexts/AppContext';
import { useCustomMixpanel } from '@services/Mixpanel';
import { SummaryPage } from '@src/modules';

const Summary = (props: PageProps) => {
  const mixpanel = useCustomMixpanel();
  const { verifyNoSignupInLocalStorage, storeSignupInLocalStorage } =
    useAppContext();

  return (
    <SummaryPage
      {...props}
      mixpanel={mixpanel}
      verifyNoSignupInLocalStorage={verifyNoSignupInLocalStorage}
      storeSignupInLocalStorage={storeSignupInLocalStorage}
    />
  );
};

export default Summary;
